
.topic-front-home{
  border-bottom: 1px solid #e2e2e2;
}
.title strong{
  margin-left: unset !important;
}
h3.title.d-flex.align-items-center.justify-content-start{
  margin-bottom: 0.3em;
  margin-top: 0;
}
.topic-meta{
  margin: unset !important;
  align-self: center;
}

li.votes{
  margin: 0em 1em 1em 0em !important;
}
li.response{
  margin: 0em 1em 1em 0em;
}
li.share{
  margin: 0em -1em;
}
img.avatar.avatar-25{
  margin: 0em 0.5em 0em 0em;
  border-radius: 100%;
}
.topic-details{
  display: flex;
  justify-content: space-between;
}
.topic-details-user{
  display: flex;
}
.topic-meta li{
  margin-top: 0.3em;
}

div.def-avatar {
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 100%;
  margin-right: 0.5em;
}

@media screen and (min-width:769px){
  .col-sm-4{
    max-width: 10% !important;
  }
}

@media screen and (max-width:768px) {
  .topic-front-home{
    flex-direction: column-reverse;
  }

  .forum-table{
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    flex: unset;
    max-width: unset;
    justify-content: space-around;
    margin-top: 1em;
  }
  .topic-details{
    display: block !important;
  }
  
}

.topics {
  &.front {
    margin-bottom: 60px;
    padding-bottom: 30px;
    border-bottom: 1px solid $light-gray;
    @include media-breakpoint-down(md) {
      border: 0;
      margin-bottom: 0;
      padding-bottom: 15px;
    }
  }
  .topic {
    display: block;
    margin-bottom: 30px;
    padding: 30px;
    border: 1px solid $primary;
    border-radius: 20px;
    &.front {
      @include media-breakpoint-down(md) {
        padding: 20px;
        border-radius: 10px;
        background: url(../images/arrow-detail.png) no-repeat 94% center;
        background-size: 8px;
      }
      h6 {
        @include media-breakpoint-down(md) {
          font-size: 16px;
        }
      }
      article,
      p {
        @include media-breakpoint-down(md) {
          font-size: 12px;
          line-height: 1.5;
          margin-bottom: 0;
          margin-right: 30px;
        }
      }

      strong {
        @include media-breakpoint-down(md) {
          font-size: 12px;
          display: inline-block;
          margin-top: 10px;
        }
      }

    }
    h6 {
      color: $black;
      font-weight: bold;
      font-size: 24px;
      font-family: 'Jenius Sans';
    }

    article,
    p {
      font-size: 14px;
      font-family: 'Open Sans';
      transition: none;
    }
    strong {
      color: $primary;
      font-weight: bold;
      font-size: 14px;
    }
  }
}

.topic-footer {
  display: contents;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0 10px;
  margin-top: 1em;
  padding: 0;
  list-style: none;
  font-size: 14px;

  li {
    &:first-child {
      margin-right: auto;
      margin-left: 0;
      a {
        background: url(../images/icon-votes.png) left center no-repeat;
        &.active{
          background: url(../images/icon-votes_active.png) left center no-repeat;
        }
      }
    }
    &:nth-child(2) {
      width: 80px;
      a {
        background: url(../images/icon-response.png) left center no-repeat;
      }
    }
    &:last-child {
      padding-left: 0;

      a {
        margin-left: -0.2em;
        background: url(../images/icon-share.png) right center no-repeat;
        text-align: left;
        text-indent: -99999em;
      }
    }
    a {
      display: inline-block;
      padding-top: 5px !important;
      padding-left: 40px;
      color: $black;
      font-weight: 500;
      background-size: 1.4rem !important;
    }
  }
}

.topics-list-load-more, #load-more {
  padding: 20px 0;
  text-align: center;
  @include media-breakpoint-down(md) {
    border-top: 1px solid $light-gray;
  }
  a, span {
    color: $light-gray;
    font-size: 55px;
    font-family: 'Jenius Sans';
    font-weight: bold;
    @include media-breakpoint-down(md) {
      font-size: 40px;
    }
    &:hover {
      color: $gray-400;
    }
  }
}