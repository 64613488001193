#buddypress {
  &:before {
    display: none;
  }

  .buddy {
    @include media-breakpoint-down(md) {
      padding: 0 30px;
    }
  }

  div.item-list-tabs#subnav ul li.last {
    &#members-order-select {
      display: none;
    }
  }

  .bbp-pagination {
    display: none;
  }

  #bbpress-forums {
    @include media-breakpoint-down(md) {
      overflow: inherit;
    }
  }

  .activity-list li.load-more {
    margin: 0 0 -20px;
    padding: 20px 0;
    border: 0;
    background: none;
    text-align: center;
    text-align: left;
    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
    a,
    span {
      position: relative;
      z-index: 0;
      display: block;
      width: 100%;
      height: 70px;
      color: $light-gray;
      font-weight: bold;
      font-size: 55px;
      font-family: "Jenius Sans";
      @include media-breakpoint-down(md) {
        font-size: 40px;
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: #f6f6f9;
        content: "Load More";
        text-align: center;
      }
      &:hover {
        color: $gray-400;
      }
    }
    &.loading a,
    span {
      &:before {
        content: "Loading...";
      }
    }
  }
  .page-heading {
    color: $dark-gray;
    text-align: left;
    &.light {
      @include media-breakpoint-down(md) {
        border-bottom: 3px solid $light-gray;
        margin: 0 -30px 30px;
        font-size: 14px;
        text-align: center;
        font-family: "Open Sans";
        font-weight: 500;
        padding-bottom: 10px;
        color: $primary;
        opacity: 1;
        position: relative;
        &:before {
          position: absolute;
          background: $primary;
          width: 50%;
          height: 3px;
          content: "";
          bottom: -3px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  .item-list-tabs {
    margin: 0 0 30px !important;
    font-family: "Open Sans";
    @include media-breakpoint-down(md) {
      margin: 0 -30px 30px !important;
    }
    ul,
    ol {
      display: flex;
      margin: 0;
      padding: 0;
      border-bottom: 1px solid $light-gray;
      list-style: none;
      @include media-breakpoint-down(md) {
        border-width: 3px;
        li {
          flex-basis: 50%;
          text-align: center;
          font-size: 14px;
        }
      }
      &.settings {
        li:last-child {
          display: none;
        }
      }
      &.profile {
        li:first-child,
        li:nth-child(4),
        li:last-child {
          display: none;
        }
      }
    }
    li {
      position: relative;
      bottom: -3px;
      border-bottom: 1px solid $light-gray;
      @include media-breakpoint-down(md) {
        border-width: 3px;
      }
      &#activity-filter-select {
        display: none;
      }
      &:hover:not(.selected) {
        border-bottom: 1px solid $primary;
        @include media-breakpoint-down(md) {
          border-width: 3px;
        }
      }
      a {
        color: $dark-gray;
        &:hover {
          color: $primary;
        }
      }
    }
    .selected {
      border-bottom: 3px solid $primary;
      @include media-breakpoint-down(md) {
        border-width: 3px;
      }
      a {
        background: none !important;
        color: $primary !important;
        font-weight: 700 !important;
      }
    }
  }
  .standard-form {
    text-align: left;
    font-family: "Open sans";
    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
      padding-top: 10px;
      * {
        font-size: 14px !important;
      }
      .co-button {
        font-size: 12px !important;
      }
    }
    .page-heading {
      @include media-breakpoint-down(md) {
        font-size: 30px !important;
        font-weight: 500;
      }
    }
    p,
    .info {
      text-align: left;
      font-size: 14px;
      line-height: normal;
    }
    .row {
      margin-bottom: 20px;
      legend {
        display: none;
      }
      input {
        @extend .form-control;
      }
    }
    #avatar-crop-submit {
      @extend .co-button;
    }
    .jcrop-holder {
      margin-right: 30px !important;
    }
    .custom-file-label {
      color: $darker-gray;
      font-weight: normal;
      font-size: 14px;
      &:after {
        padding-right: 20px;
        padding-left: 20px;
        color: $black;
        font-weight: bold;
        font-size: 15px;
      }
    }
    .delete {
      margin-top: 30px;
      padding-top: 30px;
      border-top: 1px solid $light-gray;
    }
    .submit {
      margin-top: 10px;
      input.co-button {
        width: 150px;
        border: 0;
        background: $primary;
        color: white;
        font-size: 12px;
        line-height: 35px;
      }
    }
    .input-group-text {
      background: white;
      &.readonly {
        background: lighten($dark-gray, 24%) !important;
      }
    }
    .form-control,
    .settings-input {
      margin: 0 !important;
      height: 44px;
      border-radius: 0.25rem !important;
      background: white !important;
      &[readonly] {
        background: lighten($dark-gray, 24%) !important;
      }
    }
    .input-group {
      .form-control {
        border-radius: 0 0.25rem 0.25rem 0 !important;
      }
    }
  }
  .notification-settings {
    margin: 0 !important;
    &#activity-notification-settings {
      margin-top: 20px !important;
    }
    th,
    td {
      border: 0;
    }
    thead {
      display: none;
    }
    tr {
      display: flex;
      flex-direction: row-reverse;
      td {
        align-content: center;
        align-items: center;
        padding-right: 0;
        width: 100%;
        vertical-align: middle;
        &.yes {
          width: 35px;
        }
        &:nth-child(1),
        &.no {
          display: none;
        }
        input[type="radio"] {
          position: relative;
          top: -2px;
          margin: 0;
          padding: 0;
          visibility: hidden;
          &:before {
            visibility: visible;
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            border: 1px solid $darker-gray;
            border-radius: 10px;
            background: #f6f6f9;
            content: "";
          }
          &.checked,
          &[checked] {
            &:before {
              border-color: #8fcd4d;
              background: #8fcd4d;
              color: white;
              text-align: center;
              font-size: 10px !important;
              line-height: 17px !important;

              @include fa-icon;
              @extend .fa;
              @extend .fa-#{check}:before;
            }
          }
        }
      }
    }
    input[value="no"] {
      position: absolute !important;
      display: none;
      visibility: hidden;
      overflow: hidden;
      clip: rect(1px, 1px, 1px, 1px);
      width: 1px;
      height: 1px;
      word-wrap: normal !important;
    }
  }
}

#activity-stream {
  margin: 0;
  padding: 0;
  border-top: 0 !important;
  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
  }
  * {
    font-family: "Open Sans";
  }
  li {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    @include media-breakpoint-down(md) {
      margin: 0;
      flex-direction: row;
      display: flex;
    }
  }
  .activity-avatar {
    @include media-breakpoint-down(md) {
      float: none !important;
      flex-basis: 15%;
      width: 15%;
      margin-right: 15px;
    }
    img {
      border-radius: 50%;
      margin: 0 !important;
      width: 48px !important;
      height: auto !important;
      @include media-breakpoint-down(md) {
        float: none !important;
        overflow: hidden;
      }
    }
  }
  .activity-content {
    @include media-breakpoint-down(md) {
      margin: 0 !important;
      flex-basis: 85%;
    }
  }
  .activity-comments {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .activity-header {
    display: flex;
    align-items: center;
    height: 50px;
    @include media-breakpoint-down(md) {
      height: inherit;
      margin-top: -5px;
    }
    * {
      font-size: 14px;
      line-height: 1.5;
    }
    p,
    .time-since {
      color: $dark-gray;
    }
    .time-since {
      font-size: 12px;
      display: block;
      margin-top: 5px;
      color: lighten($dark-gray, 5%);
      font-family: "Jenius sans";
    }
  }
  .activity-inner {
    margin-top: 20px !important;
    margin-bottom: 20px;
    padding-left: 20px;
    border-width: 4px;
    * {
      font-size: 14px;
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  .activity-read-more {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
}

#subscriptions-personal-li,
#bbpvotes-karma-personal-li,
body.bp-user.my-account.forums .item-list-tabs {
  display: none !important;
}

body.bp-user.my-account.forums {
  .fsticky {
    background: none !important;
  }
  .js_bbtopics_loadmore.loading {
    box-shadow: none !important;
    -webkit-animation: none !important;
    -moz-animation: none !important;
  }
}
#bbpress-forums {
  .entry-title {
    margin-bottom: 15px;
    font-weight: normal;
    font-size: 36px;

    @extend .page-heading;
    @include media-breakpoint-down(md) {
      border-bottom: 3px solid $light-gray;
      margin: 0 -30px 40px;
      font-size: 14px;
      text-align: center !important;
      font-family: "Open Sans";
      font-weight: 500;
      padding-bottom: 10px;
      color: $primary;
      opacity: 1;
      position: relative;
      &:before {
        position: absolute;
        background: $primary;
        width: 50%;
        height: 3px;
        content: "";
        bottom: -3px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .bbp-user-section {
    overflow: hidden;
    margin-top: 30px;
    .featured {
      margin-top: 0;
      .title {
        strong {
          font-size: inherit;
        }
      }
    }
    .topic {
      padding-right: 0;
      padding-left: 0;
      border-width: 1px 0 0;
      border-radius: 0;
    }
  }
}

body.bp-user.my-account.profile-edit #menu-main.members li:first-child a,
body.bp-user.my-account.forums #menu-main.members li:nth-child(6) a,
body.bp-user.my-account.notifications.unread
  #menu-main.members
  li:nth-child(5)
  a,
body.bp-user.my-account.my-activity #menu-main.members li:nth-child(4) a,
body.bp-user.my-account.settings.notifications
  #menu-main.members
  li:nth-child(3)
  a,
body.bp-user.my-account.settings #menu-main.members li:nth-child(3) a,
body.bp-user.my-account.change-avatar #menu-main.members li:nth-child(2) a,
body.bp-user.my-account.point-history #menu-main.members li:nth-child(7) a {
  color: $black !important;
}

body.bp-user.my-account.notifications {
  #pag-top,
  #pag-bottom {
    padding: 15px 0;
    font-family: "Open Sans";
    .pag-count,
    .pagination-links {
      margin: 0;
    }
  }
  #pag-bottom {
    @include media-breakpoint-down(md) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  #notifications-bulk-management {
    margin-top: 30px;
    .notifications {
      font-family: "Open Sans";
      @include media-breakpoint-down(md) {
        display: flex;
      }
      thead {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
      th,
      td {
        padding: 15px 10px !important;
        border-color: $light-gray;
        text-align: left;
        font-size: 14px;
      }
      td {
        vertical-align: top !important;
        @include media-breakpoint-down(md) {
          border: 0;
        }
      }
      tr {
        @include media-breakpoint-down(md) {
          display: flex;
          flex-wrap: wrap;
          border-bottom: 1px solid $light-gray;
        }
      }
      .bulk-select-check {
        @include media-breakpoint-down(md) {
          width: inherit;
          flex-basis: 10%;
        }
      }
      .notification-description {
        width: 60% !important;
        @include media-breakpoint-down(md) {
          width: inherit;
          flex-basis: 90%;
          padding-bottom: 5px !important;
        }
      }
      .notification-since {
        color: $dark-gray;
        @include media-breakpoint-down(md) {
          flex-basis: 90%;
          margin-left: auto;
          padding-top: 0 !important;
          font-size: 11px;
          font-weight: normal;
        }
      }
      .notification-actions {
        @include media-breakpoint-down(md) {
          flex-basis: 90%;
          margin-left: auto;
          padding-top: 0 !important;
          color: $dark-gray;
        }
        a {
          @include media-breakpoint-down(md) {
            padding-left: 5px;
            margin-right: 5px;
            font-size: 12px;
          }
          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
    .notifications-options-nav {
      display: flex;
      padding-bottom: 15px;
      border-bottom: 1px solid $light-gray;
      select {
        width: 120px;
        border: 0;

        @extend .form-control;
        -webkit-appearance: menulist;
        -moz-appearance: menulist;
        @include media-breakpoint-down(md) {
          width: inherit;
          flex-basis: 50%;
        }
      }
      .button {
        margin-left: 15px;
        height: calc(2.25rem + 2px);
        border-radius: 5px;
        line-height: calc(2.25rem + 4px);

        @extend .co-button;
        @include media-breakpoint-down(md) {
          width: inherit;
          flex-basis: 50%;
        }
      }
    }
  }
}

body.buddypress {
  .header-profile {
    margin: 40px 0;
    @include media-breakpoint-down(md) {
      margin-top: 0;
    }
    .my-avatar {
      position: relative;
      display: flex;
      justify-content: left;
      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
      }
      img,
      .def-avatar {
        width: 100px;
        height: 100px;
        border-radius: 75px;
      }
      .def-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: bold;
        font-size: 60px;
        font-family: "Jenius sans";
      }
      .fa {
        position: absolute;
        top: 20px;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: $black;
        color: white;
        font-size: 18px;
        transform: translateX(50px);
      }
    }
    .my-profile {
      display: flex;
      align-items: start;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      font-family: "Jenius sans";
      @include media-breakpoint-down(md) {
        align-items: center;
        flex: 0 0 100%;
        margin-bottom: 30px;
      }
      h2 {
        color: $black;
        font-weight: bold;
        font-size: 32px;
        @include media-breakpoint-down(md) {
          font-size: 28px;
        }
      }
      h3 {
        font-size: 18px;
        font-weight: 600;
        color: $dark-gray;
        @include media-breakpoint-down(md) {
          font-size: 14px;
        }
      }
      .role {
        font-size: 14px;
        color: $dark-gray;
        @include media-breakpoint-down(md) {
          font-size: 12px;
        }
      }
    }
  }
  .badges {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    @include media-breakpoint-down(md) {
      align-items: center;
    }
    .co-button {
      width: 100%;
      text-align: center;
      background: $dark-gray;
      &:hover {
        background: darken($dark-gray, 15%) !important;
      }
    }
  }

  #profile-edit-form,
  #settings-form {
    @include media-breakpoint-down(md) {
      .col {
        flex-basis: 100%;
      }
    }
  }
  #settings-form {
    @include media-breakpoint-down(md) {
      .col {
        margin-bottom: 15px;
      }
      .row {
        margin-bottom: 0;
      }
    }
  }
}

#avatar-upload-form.standard-form {
  .page-heading {
    @include media-breakpoint-down(md) {
      font-size: 16px !important;
    }
  }
  .jcrop-holder {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}
