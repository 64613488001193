.aside-nav {
  @include media-breakpoint-down(md) {
    display: none !important;
    &.mshow {
      display: inherit !important;
    }
    &.mnav {
      position: fixed;
      left: 0;
      top: 0;
      height: calc(100% - 60px); //160 is header height + footer height
      width: 100%;
      background: white;
      z-index: 5;
      display: none;
      overflow: scroll;
      .main {
        margin-top: 100px;
      }
      ul,
      ol {
        margin: 0 60px;
        padding-top: 20px;
        li {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
      .login-status {
        margin: 20px;
        padding: 0;
        justify-content: space-evenly;
        li {
          width: 44%;
        }
        .co-button {
          width: 100%;
          text-align: center;
          &.register {
            background: #000;
          }
        }
      }
    }
  }
  width: 240px;
  margin-bottom: 2rem;
  ol,
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    &.menu-social {
      margin-top: 40px;
    }
  }
  .main {
    margin-top: -8px;
    li {
      padding-left: 40px;
      background: url(../images/icon-nav-home.png) left center no-repeat;
      background-size: 20px;
      font-weight: 500;
      font-size: 16px;
      line-height: 2;
      &.active,
      &.current-menu-item {
        background-image: url(../images/icon-nav-home-active.png);
        a {
          color: $black;
        }
      }
      &:nth-child(2) {
        background-image: url(../images/icon-nav-event.png);
        &.active, &.current-menu-item {
          background-image: url(../images/icon-nav-event-active.png);
        }
      }
      &:nth-child(3) {
        background-image: url(../images/icon-nav-webinar.png);
        &.active, &.current-menu-item {
          background-image: url(../images/icon-nav-webinar-active.png);
        }
      }
      &:nth-child(4) {
        background-image: url(../images/icon-nav-blog.png);
        &.active, &.current-menu-item {
          background-image: url(../images/icon-nav-blog-active.png);
        }
      }
      &:nth-child(5) {
        background-image: url(../images/icon-nav-challenge.png);
        &.active, &.current-menu-item {
          background-image: url(../images/icon-nav-challenge-active.png);
        }
      }
      &:nth-child(6) {
        background-image: url(../images/icon-nav-discussion.png);
        &.active, &.current-menu-item {
          background-image: url(../images/icon-nav-discussion-active.png);
        }
      }
      &:nth-child(7) {
        background-image: url(../images/icon-nav-exp.png);
        &.active, &.current-menu-item {
          background-image: url(../images/icon-nav-exp-active.png);
        }
      }
      &:nth-child(8) {
        background-image: url(../images/icon-nav-ideas.png);
        &.active, &.current-menu-item {
          background-image: url(../images/icon-nav-ideas-active.png);
        }
      }
      a {
        position: relative;
        top: 2px;
        color: $darker-gray;
        &:hover {
          color: $black;
        }
      }
    }
    &.members {
      li {
        &:first-child {
          background-image: url(../images/icon-profile-edit.png);
          &.active {
            background-image: url(../images/icon-profile-edit.png);
          }
        }
        &:nth-child(2) {
          background-image: url(../images/icon-profile-avatar.png);
          &.active {
            background-image: url(../images/icon-profile-avatar.png);
          }
        }
        &:nth-child(3) {
          background-image: url(../images/icon-profile-settings.png);
          &.active {
            background-image: url(../images/icon-profile-settings.png);
          }
        }
        &:nth-child(4) {
          background-image: url(../images/icon-profile-activity.png);
          &.active {
            background-image: url(../images/icon-profile-activity.png);
          }
        }
        &:nth-child(5) {
          background-image: url(../images/icon-profile-notif.png);
          &.active {
            background-image: url(../images/icon-profile-notif.png);
          }
        }
        &:nth-child(6) {
          background-image: url(../images/icon-profile-thread.png);
          &.active {
            background-image: url(../images/icon-profile-thread.png);
          }
        }
        &:nth-child(7) {
          background-image: url(../images/icon-point.png);
          &.active {
            background-image: url(../images/icon-point.png);
          }
        }
        &:nth-child(8) {
          background-image: none;
          &.active {
            background-image: none;
          }
        }
      }
    }
  }
  .site-info {
    position: relative;
    padding: 20px;
    border-radius: 10px;
    background: $gray;
    .flag {
      position: absolute;
      top: -4px;
      right: 10px;
      width: 20px;
      height: 40px;
      background: url(../images/icon-bookmark.png) 95% top no-repeat;
    }
    h5,
    p,
    a {
      color: $dark-gray;
      font-weight: 500;
      font-size: 14px;
    }
    p,
    a {
      margin-bottom: 0;
      color: $black;
    }
  }
  &.secondary {
    text-align: center;
    z-index: 15;
    .off {
      h6 {
        margin-bottom: 20px;
        color: $primary;
        font-size: 18px;
        line-height: 1.5;
      }
    }
    .in nav {
      margin-top: 0;
    }
    @include media-breakpoint-down(md) {
      &.frh .off {
        h6,
        .co-button {
          display: none;
        }
      }
      &.mshow.frh {
        width: 100%;
        margin: 0;
        padding: 0;
        nav {
          margin: 15px 0 -15px;
          ul,
          ol {
            display: -webkit-box;
            li {
              width: 33.33%;
            }
            a {
              color: $black;
              text-transform: inherit;
              font-size: 10px;
            }
            strong {
              display: inherit;
              font-size: 10px;
            }
          }
        }
      }
      &.frf {
        display: block !important;
        width: 100%;
        padding: 0;
        .off {
          width: 100%;
          nav {
            margin-top: 0;
            background: #f6f6f9;
          }
          ul {
            display: -webkit-box;
            flex-wrap: wrap;
            justify-content: center;
            li {
              width: 33%;
              height: 130px;
              flex: 0 0 50%;
              margin: 0;
              padding: 10px 0;
              &:last-child {
                margin-bottom: 10px;
              }
              &.join {
                background: $primary;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                h6,
                a {
                  display: block;
                  color: white;
                  margin-bottom: 5px;
                  font-family: "Jenius Sans";
                }
                h6 {
                  font-size: 15px;
                  padding-top: 10px;
                }
              }
              a {
                display: block;
              }
              em {
                background-color: transparent;
                margin-bottom: 0;
              }
            }
          }
        }
        .in {
          nav {
            margin-bottom: 0;
          }
          ul {
            flex-wrap: nowrap;
            li {
              flex: 0 0 33.33%;
              margin: 10px 0;
              height: inherit;
              a {
                color: $black;
                text-transform: inherit;
              }
              em {
                background-color: lighten($light-gray, 5%);
                margin-bottom: 10px;
              }
              strong {
                display: inline;
                font-size: inherit;
              }
            }
          }
        }
      }
    }
    nav {
      margin-top: 40px;
      li {
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0;
        }
        em {
          display: block;
          margin: 0 auto 10px;
          width: 66px;
          height: 66px;
          border-radius: 33px;
          background: url(../images/ide-icon.svg) center no-repeat;
        }
        &.article em {
          background-image: url(../images/tulis-article-icon.svg);
        }
        &.thread em {
          background-image: url(../images/thread-baru-icon.svg);
        }
        a {
          text-transform: uppercase;
          font-weight: 600;
          font-size: 10px;
          strong {
            display: block;
            color: $black;
            text-transform: none;
            font-weight: 600;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.footer-menu {
  position: fixed;
  z-index: 90;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background: #f6f6f9;
  max-height: 60px;
  width: 100%;
  transition: max-height 0.15s ease-out;
  box-shadow: 0px 0px 4px lightgray;
  .start-menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 60px;
    background: white;
    color: white;
    text-align: center;
    font-size: 20px;
    z-index: 50;
    width: 100%;
    position: relative;
    img {
      display: block;
      width: 32px;
      height: 32px;
      border-radius: 50px;
    }
    em {
      display: block;
      background-color: red;
      width: 20px;
      height: 20px;
      font-size: 8px;
      border-radius: 50%;
      padding: 5px;
      color: white;
      margin-left: 18px;
      margin-top: -37px;
      position: absolute;
      font-weight: 500;
      justify-content: center;
      font-style: normal;
      font-family: "Jenius Sans";
    }
    a>span.def-avatar {
      display: block;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      color:#ffffff;
    }
    
    .nav-menu {
      display: block;
      width: 32px;
      height: 32px;
      border-radius: 33px;
      &.burger .top-menu {
        color: #aaaaaa;
        &.active {
          color: #13a1d7;
        }
      }
      &.action {
        background: url(../images/actionmenu-inactive.svg) center no-repeat;
      }
      &.home {
        background: url(../images/home-inactive.svg) center no-repeat;
        &.active {
          background: url(../images/home-active.svg) center no-repeat;
        }
      }
      &.profile {
        background: url(../images/user-icon.svg) center no-repeat;
      }
    }
  }
  &:before {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: "";
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.15s ease-out;
    visibility: hidden;
  }
  &.open {
    max-height: 304px;
    transition: max-height 0.15s ease-in;
    &:before {
      visibility: visible;
      background-color: rgba(0, 0, 0, 0.2);
      transition: background-color 0.15s ease-in;
    }
    .start-menu {
      //background: darken($primary, 10%);
      background: white;
      .fa {
        transform: rotate(180deg);
      }
      .nav-menu {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 33px;
        &.action {
          background: url(../images/actionmenu-active.svg) center no-repeat;
        }
        &.home {
          background: url(../images/home-inactive.svg) center no-repeat;
        }
        &.profile {
          background: url(../images/user-icon.svg) center no-repeat;
        }
      }
    }
  }
}

body.bp-user #menu-main.mb {
  display: block;
  margin: 0 -15px;
  padding: 0;
  list-style: none;
  border-top: 2px solid $primary;
  &.members {
    li {
      padding-left: 60px;
      background: url(../images/icon-nav-home.png) 20px center no-repeat;
      background-size: 20px;
      font-weight: 500;
      font-size: 16px;
      line-height: 2;
      border-bottom: 1px solid $light-gray;
      margin: 0 20px;
      a {
        position: relative;
        top: 3px;
      }
      &:first-child {
        background-image: url(../images/icon-profile-edit.png);
        &.active {
          background-image: url(../images/icon-profile-edit.png);
        }
      }
      &:nth-child(2) {
        background-image: url(../images/icon-profile-avatar.png);
        &.active {
          background-image: url(../images/icon-profile-avatar.png);
        }
      }
      &:nth-child(3) {
        background-image: url(../images/icon-profile-settings.png);
        &.active {
          background-image: url(../images/icon-profile-settings.png);
        }
      }
      &:nth-child(4) {
        background-image: url(../images/icon-profile-activity.png);
        &.active {
          background-image: url(../images/icon-profile-activity.png);
        }
      }
      &:nth-child(5) {
        background-image: url(../images/icon-profile-notif.png);
        &.active {
          background-image: url(../images/icon-profile-notif.png);
        }
      }
      &:nth-child(6) {
        background-image: url(../images/icon-profile-thread.png);
        &.active {
          background-image: url(../images/icon-profile-thread.png);
        }
      }
      &:nth-child(7) {
        background-image: url(../images/icon-point.png);
        border: 0;
        &.active {
          background-image: url(../images/icon-point.png);
        }
      }
      &:nth-child(8) {
        background-image: none;
        padding: 0;
        text-align: center;
        background: #13a1d7;

        a {
          color: #fff;
        }

        &.active {
          background-image: none;
        }
      }
    }
  }
}
