.site-footer {
  padding: 40px 0;
  background: white;
  @include media-breakpoint-down(md) {
    padding: 40px 15px;
    margin-top: 10px;
  }
  nav {
    ul,
    ol {
      margin: 0;
      padding: 0;
      list-style: none;
      &.jen-menu {
        @include media-breakpoint-down(md) {
          display: none
        }
      }
    }
    li {
      font-size: 14px;
      font-family: 'Open Sans';
      line-height: 2.4;
      @include media-breakpoint-down(md) {
        font-size: 12px;
        line-height: 1.5;
      }
      &:first-child {
        color: $darker-gray;
        font-weight: 600;
        font-size: 16px;
        @include media-breakpoint-down(md) {
          font-size: 14px;
        }
      }
      a {
        color: $dark-gray;
        &:hover {
          color: $dark;
        }
      }
    }
  }
  .subs {
    h6 {
      text-align: left;
      margin-top: 6px;
      color: $darker-gray;
      font-weight: 600;
      font-size: 16px;
    }
    .form-control {
      padding: 0 20px;
      padding-right: 150px;
      height: 40px;
      border: 1px solid $light-gray;
      border-radius: 20px;
      color: $dark !important;
      font-size: 12px;
    }
    .input-group {
      position: relative;
      margin-top: 24px;
    }
    .co-button {
      position: absolute;
      top: 0;
      right: -1px;
      z-index: 20;
      width: 130px;
    }
  }
  &.secondary {
    background: transparent;
    @include media-breakpoint-down(md) {
      border-top: 1px solid $light-gray;
      background: white;
      margin-top: 0;
      padding-bottom: 64px;
    }
    article,
    p {
      color: $dark-gray;
      font-size: 12px;
      line-height: 1.5
    }
  }
}

.jen-long {
  display: none;
  @include media-breakpoint-down(md) {
    display: inherit;
    margin-top: 40px;
    height: 36px;
    margin-left: 15px;
  }
}

.popout {
  z-index: 1000;
  background:rgba(0,0,0,.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  .inner {
    padding: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 10px;
  }

  h6 {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .fa-times {
    position: absolute;
    right: 15px;
    top: 15px;
    opacity: 0.5;
    cursor: pointer;
  }
}

#welcome {
  .inner {
    width: 700px;
    padding: 40px;
    @include media-breakpoint-down(md) {
      width: 90%;
    }
  }
  .fa-times {
    top: 20px;
    right: 20px;
  }
  h3 {
    font-family: 'Jenius sans';
    font-size: 30px;
    color: $primary;
    font-weight: bold;
    margin-bottom: 20px;
    @include media-breakpoint-down(md) {
      font-size: 18px !important;
      font-weight: normal;
      margin-bottom: 10px;
    }
  }
  p {
    font-family: 'Jenius sans';
    font-size: 18px;
    color: $dark-gray;
    @include media-breakpoint-down(md) {
      font-size: 13px;
      line-height: 1.5;
    }
  }
  .inner-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
      justify-content: center;
    }
    .content {
      width: 60%;
      @include media-breakpoint-down(md) {
        width: 100%;
        text-align: center;
      }
    }
    img {
      width: 50%;
      margin-left: 10px;
      @include media-breakpoint-down(md) {
        width: 60%;
        margin: 0 0 30px;
      }
    }
    ul {
      display: flex;
      margin: 30px 0 0;
      padding: 0;
      list-style: none;

      @include media-breakpoint-down(md) {
        margin-top: 10px;
        justify-content: center;
      }
      li {
        margin-right: 15px;
        @include media-breakpoint-down(md) {
          margin: 0 10px;
        }
        &:last-child .co-button {
          background: $black;
          &:hover {
            background: darken($black, 10%) !important;
          }
        }
      }
      .co-button {
        width: 120px;
        text-align: center;
        @include media-breakpoint-down(md) {
          width: 100px;
          font-size: 10px;
        }
      }
    }
  }
}

#share-it {
  display: none;
  .inner {
    width: 500px;
    @include media-breakpoint-down(md) {
      width: 90%;
    }
    ul {
      display: flex;
      margin: 30px 0 0;
      list-style: none;
      padding: 0;
      li:first-child {
        width: 100px;
        font-size: 14px;
        font-weight: bold;
        padding-top: 6px;
      }
      li:nth-child(2) a {
        color: #3C5A99;
      }
      li:nth-child(3) a {
        color: #1DA1F2
      }
      li:nth-child(4) a {
        color: #4AC959
      }
      a {
        padding: 0 15px;
      }
      .fa {
        font-size: 25px !important;
      }
      img {
        width: 22px;
      }
    }
    .form-inline {
      position: relative;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      @include media-breakpoint-down(md) {
        margin-top: 30px;
      }
      .btn {
        margin: 0;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 44px;
        width: 44px;
        border-radius: 22px;
      }
      .form-control {
        width: 100%;
        height: 44px;
        border: 0;
        border-radius: 50px;
        padding-left: 20px;
        padding-right: 50px;
        font-size: 14px;
        padding-top: 5px;
        padding-bottom: 0;
      }
    }
    label {
      width: 120px;
      opacity: 0.3;
      font-size: 16px;
      justify-content: flex-start;
      padding-bottom: 10px;
      margin-top: 10px;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}

.kv-link {
  width: 100%;
  height: 100%;
  display: block;
  .btn {
    font-family: 'Jenius Sans';
    font-size: 1rem;
    left: 20px;
    top: 20px;
    border-radius: 20px !important;
    padding: 5px 20px;
    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  }
  picture, img {
    border-radius: 20px;
    width: 100%;
  }
}