html,
body {
  @include media-breakpoint-down(md) {
    height: auto;
    min-height: 100%;
    &.menu-active {
      overflow: hidden;
      background: white;
      .site-header-m {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 10;
        background: white;
      }
      .mnav {
        display: block !important;
      }
    }
  }
}

body {
  color: $black;
  opacity: 1;
  transition: 1s opacity;
  &.off {
    opacity: 0;
    transition: none;
  }
  a:hover {
    text-decoration: none;
  }
  &.share-popout,
  &.welcome-popout {
    overflow: hidden;
    #share-it {
      display: block !important;
    }
  }
}

p,
li {
  color: $dark;
  font-size: 14px;
  font-family: "Open Sans";
  @include media-breakpoint-down(md) {
    font-size: 12px;
  }
}

.page-heading {
  // margin-bottom: 15px;
  font-weight: bold;
  font-size: 1.5rem;
  font-family: "Jenius Sans";
  @include media-breakpoint-down(md) {
    font-size: 24px;
  }
  &.light {
    color: $darker-gray;
    opacity: 0.6;
  }
}

.search {
  &.on-content {
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid $light-gray;
    header {
      padding-bottom: 20px;
    }
    @include media-breakpoint-down(md) {
      border: 0;
      padding: 30px 30px 50px;
      background: white;
      margin: 0 -30px 40px;
    }
  }
  &.m {
    @include media-breakpoint-down(md) {
      background: none;
      padding-bottom: 0;
      strong {
        color: $primary;
        font-size: 12px;
        margin-top: -10px;
        display: inline-block;
      }
      .start-new-thread {
        margin-top: 30px;
        margin-bottom: 0;
      }
      header {
        padding-bottom: 0;
        margin-bottom: 40px;
      }
      p {
        margin: 0 0 10px;
      }
    }
  }
  .search-form {
    position: relative;
    label span {
      display: none;
    }
    label {
      display: block;
      margin: 0;
    }
  }
  #search,
  .search-field {
    padding: 0 15px 0 60px;
    height: 40px;
    border: 0;
    border-radius: 25px;
    background: white;
    box-shadow: 0 5px 25px rgba(50, 50, 93, 0.1), 0 0px 10px rgba(0, 0, 0, 0.07);
    color: $dark;
    font-size: 14px;
  }
  button {
    width: 44px;
    height: 44px;
    padding: 0;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent url(../images/icon-search.svg) center no-repeat;
    background-size: 16px;
    border: 0;
    &:focus,
    &:active {
      outline: 0;
    }
  }
}

input::placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
  opacity: 1;
}

.hero {
  margin-bottom: 2rem;
  @include media-breakpoint-down(md) {
    margin-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 80px;
  }
  .carousel {
    border-radius: 25px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: 10%;
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    overflow: hidden;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background: rgba(0, 0, 0, 0.07) url(../images/prev-slider.png) center
      no-repeat;
    background-size: cover;
  }
  .carousel-control-next-icon {
    background-image: url(../images/next-slider.png);
  }
  .carousel-indicators {
    bottom: -40px;
    li {
      width: 8px;
      height: 8px;
      background: $dark-gray;
      border-radius: 4px;
      margin: 0 10px;
      &.active {
        background: $black;
      }
    }
  }
  & &-btn {
    font-size: 1rem;
    background-color: white;
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding: 5px 25px;
    font-family: "Jenius Sans";
    border-radius: 25px;
    @include media-breakpoint-down(md) {
      font-size: .6rem;
    }
    &:hover, &:active {
      background-color: $dark-gray;
    }
  }
}

.topic-meta {
  margin: 25px 0 20px !important;
  padding: 0;
  color: $darker-gray;
  list-style: none;
  font-weight: 500;
  flex-wrap: wrap;
  li {
    img {
      border-radius: 100%;
      display: inline;
    }
    padding: 0 !important;
    font-size: .8rem !important;
    position: relative !important;
    strong {
      color: #666 !important;
    }
  }
}

.co-button {
  display: inline-block;
  padding: 0 20px;
  height: 40px;
  border: 0;
  border-radius: 20px;
  background: $primary;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  font-family: "Open Sans";
  line-height: 42px;
  &.md {
    font-size: 10px;
  }
  &:hover {
    background: darken($primary, 10%) !important;
    color: white;
  }
  &[disabled],
  &:disabled {
    opacity: 0.5;
    &:hover {
      background: $primary;
    }
  }
  &.btn-danger {
    border-color: #dc3545 !important;
    background: #dc3545 !important;
    &:hover {
      border-color: darken(#dc3545, 10%) !important;
      background: darken(#dc3545, 10%) !important;
    }
  }
  &.btn-idea-left {
    background: #13a1d7 !important;
    @include media-breakpoint-down(md) {
      font-size: 10px !important;
    }
  }
  &.btn-idea-right {
    background-color: #353535;
    @include media-breakpoint-down(md) {
      font-size: 10px !important;
    }
  }

  &.btn-profile-setting {
    background: #13a1d7 !important;
    text-transform: capitalize;
    height: 30px;
    line-height: 34px;
    float: left;
    text-align: center;
    width: 110% !important;
    @include media-breakpoint-down(md) {
      font-size: 10px !important;
    }
  }

  &.btn-profile-logout {
    background: #b6b6b6 !important;
    text-transform: capitalize;
    height: 30px;
    line-height: 34px;
    float: right;
    text-align: center;
    width: 45%;
    @include media-breakpoint-down(md) {
      font-size: 10px !important;
      width: 100%;
    }
  }

  &.btn-profile-delete {
    background: #dc3545 !important;
    text-transform: capitalize;
    height: 30px;
    line-height: 34px;
    text-align: center;
    width: 110% !important;
    @include media-breakpoint-down(md) {
      font-size: 10px !important;
    }
  }
}

.submit-idea-heading {
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.no-border {
  border: 0 !important;
}

.content-submission {
  margin-bottom: 45px;
  padding-bottom: 40px;
  padding-top: 30px;
  border-bottom: 1px solid $light-gray;
  margin-bottom: 45px;
  @include media-breakpoint-down(md) {
    background: white;
    margin-right: -30px;
    margin-left: -30px;
    padding-left: 30px;
    padding-right: 30px;
  }
  h2 {
    margin: 0 0 20px;
    padding: 0;
    font-weight: bold;
    font-size: 26px;
    font-family: "Jenius Sans";
    line-height: 1.5;
    @include media-breakpoint-down(md) {
      font-size: 20px;
    }
  }
  .co-button {
    width: 48%;
    border: 0;
    text-align: center;
    &:last-child {
      background: #353535;
      &:hover {
        background: darken(#353535, 10%) !important;
      }
    }
  }
}

.menu-social {
  margin: 40px 0;
  &.bottom {
    margin-top: 0;
  }
  li {
    display: flex;
    align-items: center;
    text-align: center;
    a {
      position: relative;
      width: 38px;
      height: 38px;
      border: 1px solid $light-gray;
      border-radius: 19px;
      line-height: 38px;
      &:before {
        font-size: 18px;
        @extend .fa;
        @extend .fa-facebook;
      }
      em,
      span {
        display: inline-block;
        text-align: left;
        text-indent: -99999em;
      }
    }
    &:nth-child(2) a:before {
      @extend .fa-twitter;
    }
    &:nth-child(3) a:before {
      @extend .fa-instagram;
    }
    &:nth-child(4) a:before {
      @extend .fa-youtube-play;
    }
  }
}

.author-profile-card {
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid $light-gray;
  border-bottom: 1px solid $light-gray;
  display: flex;
  align-items: center;
  p {
    font-size: 12px;
    font-family: "Open Sans";
    display: table-cell;
    strong {
      font-size: 10px;
    }
  }
  .desc {
    text-align: justify;
  }
}
.author-photo {
  float: left;
  margin-right: 15px;
  text-align: left;
  padding: 5px;
  .def-avatar {
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-size: 21px;
    font-family: "Jenius Sans";
  }
  img {
    border-radius: 75px;
  }
}

.header-submit-idea {
  display: block;
  height: 60px;
  margin-top: 10px;

  .icon-close {
    width: 32px;
    height: 32px;
    background: url(../images/icon-close.png) center no-repeat;
    background-size: contain;
    text-indent: -99999em;
    float: right;
    margin-top: 10px;
    margin-right: 20px;
  }
}

.profil-icon {
  width: 30px;
  height: 30px;
  &.user {
    background: url(../images/ico_username.png) center no-repeat;
    background-size: contain;
  }
  &.cashtag {
    background: url(../images/ico_cashtag.png) center no-repeat;
    background-size: contain;
  }
  &.email {
    background: url(../images/ico_email.png) center no-repeat;
    background-size: contain;
  }
  &.phone {
    background: url(../images/ico_phone.png) center no-repeat;
    background-size: contain;
  }
  &.location {
    background: url(../images/ico_location.png) center no-repeat;
    background-size: contain;
  }
  &.web {
    background: url(../images/ico_web.png) center no-repeat;
    background-size: contain;
  }
  &.twitter {
    background: url(../images/ico_tw.png) center no-repeat;
    background-size: contain;
  }
  &.instagram {
    background: url(../images/ico_ig.png) center no-repeat;
    background-size: contain;
  }
  &.facebook {
    background: url(../images/ico_fb.png) center no-repeat;
    background-size: contain;
  }
  &.bio {
    background: url(../images/ico_bio.png) center no-repeat;
    background-size: contain;
  }
  &.gender {
    filter: grayscale(100%);
    background: url(../images/icon-gender.png) center no-repeat;
    background-size: contain;
  }
}

ol {
  font-family: "Open Sans" !important;
}

.event-calendar {
  font-family: "Jenius Sans";
  width: 100%;
  min-height: 50px;
  overflow: hidden;
  border-bottom: 1px solid $light-gray;
  padding-bottom: 20px;
  padding-top: 20px;
  .cal-date {
    width: 20%;
    height: 25px;
    padding: 4px;
    border-radius: 4px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: #ffff;
    float: left;
    margin-right: 5px;
    margin-top: 8px;
  }
  .cal-name {
    display: block;
    width: 75%;
    float: right;
    .title {
      a {
        color: #404044;
      }
      margin: 0 0 20px;
      padding: 0;
      font-size: 18px;
      line-height: 1.5;
      text-align: left;
      line-height: 1.4;
      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
    }
    span {
      display: block;
      text-align: left;
      font-size: 10px;
    }
  }
}

.jen-post-container {
  overflow: auto;
  padding: 10px 10px 10px 10px;
  text-align: left;
  border: 1px solid #dadada;
  border-radius: 5px;
  margin-bottom: 20px;
}

.jen-post-thumb {
  float: left;
  width: 10%;
}
.jen-post-thumb img {
  display: block;
}
.jen-post-content {
  float: right;
  width: 87%;
}

.jen-post-title {
  font-weight: bold;
  font-size: 80% !important;
}

.jen-embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 10px;
}
.jen-embed-container iframe,
.jen-embed-container object,
.jen-embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.topik-pilihan {
  font-family: "Jenius Sans";
  .title-topik {
    margin-right: 5px;
    font-weight: bold;
    @include media-breakpoint-down(md) {
      display: block;
    }
  }
  .topik-tag {
    display: inline-block;
    padding: 0 10px;
    height: 24px;
    border: 10px;
    border-radius: 25px;
    border: 1px solid #13a1d7;
    color: #13a1d7;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    text-transform: none;
    margin-top: 5px;
    margin-right: 5px;
    &.active {
      background-color: #13a1d7;
      color: #ffffff !important;
    }
  }
}

#noty-holder {
  width: 100%;
  top: 0;
  font-weight: bold;
  font-size: 12px;
  z-index: 1031; /* Max Z-Index in Fixed Nav Menu is 1030*/
  text-align: center;
  position: fixed;
}

.alert {
  margin-bottom: 2px;
  border-radius: 0px;
}

.kill-the-bill-banner {
  margin-bottom: 50px;
  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
  }
}

.dot-profile-blue {
  height: 0.5rem;
  width: 0.5rem;
  background-color: #13a1d7;
  border-radius: 50%;
  display: none;
  margin-right: 0.2rem;
  margin-bottom: 0.1rem;
}

.dot-profile-red {
  height: 0.5rem;
  width: 0.5rem;
  background-color: #ff0000;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.2rem;
  margin-bottom: 0.1rem;
}

.progressbar-profile {
  width: 88%;
  height: 0.5rem;
  float: left;
  background-color: #ff0000 !important;
  @include media-breakpoint-down(md) {
    width: 70%;
  }
}

.no-js #loader { display: none;  }
.js #loader { display: block; position: absolute; left: 100px; top: 0; }
.se-pre-con {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: url(../images/Preloader_2.gif) center no-repeat #fff;
}

#buddypress ul.item-list li img.avatar {
  border-radius: 75px;
}

.topic-meta .def-avatar {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 30px;
  box-shadow: 0 3px 10px rgba(50, 50, 93, 0.1), 0 0px 5px rgba(0, 0, 0, 0.07);
  text-align: center;
  >span {
    vertical-align: middle;
    color: white;
  }
}

.font-jenius {
  font-family: 'Jenius Sans' !important;
}