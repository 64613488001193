body.buddypress {
    .isMobile {
        &.header-profile {
            .my-profile {
                align-items: baseline;
            }
            .my-avatar
            {
                .fa {
                    top: 0;
                    left: 15%;
                }
                .def-avatar,
                img {
                    width: 80px;
                    height: 80px;
                    font-size: 40px;
                }
            }
        }

        #badge-lists,
        #badge-page {
            width: 100%;
        }
    }
}
