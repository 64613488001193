//Jenius Sans font

@font-face {
  font-weight: 300;
  font-style: normal;
  font-family: 'Jenius Sans';
  src: url('../fonts/JeniusSans-Light.woff2') format('woff2'), url('../fonts/JeniusSans-Light.woff') format('woff');
}

@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'Jenius Sans';
  src: url('../fonts/JeniusSans-Regular.woff2') format('woff2'), url('../fonts/JeniusSans-Regular.woff') format('woff');
}

@font-face {
  font-weight: bold;
  font-style: normal;
  font-family: 'Jenius Sans';
  src: url('../fonts/JeniusSans-Bold.woff2') format('woff2'), url('../fonts/JeniusSans-Bold.woff') format('woff');
}

// Open Sans font

@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Regular.woff2') format('woff2'), url('../fonts/OpenSans-Regular.woff') format('woff');
}

@font-face {
  font-weight: 800;
  font-style: italic;
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-ExtraBoldItalic.woff2') format('woff2'), url('../fonts/OpenSans-ExtraBoldItalic.woff') format('woff');
}

@font-face {
  font-weight: bold;
  font-style: normal;
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Bold.woff2') format('woff2'), url('../fonts/OpenSans-Bold.woff') format('woff');
}

@font-face {
  font-weight: 800;
  font-style: normal;
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-ExtraBold.woff2') format('woff2'), url('../fonts/OpenSans-ExtraBold.woff') format('woff');
}

@font-face {
  font-weight: bold;
  font-style: italic;
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-BoldItalic.woff2') format('woff2'), url('../fonts/OpenSans-BoldItalic.woff') format('woff');
}

@font-face {
  font-weight: 300;
  font-style: normal;
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Light.woff2') format('woff2'), url('../fonts/OpenSans-Light.woff') format('woff');
}

@font-face {
  font-weight: normal;
  font-style: italic;
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Italic.woff2') format('woff2'), url('../fonts/OpenSans-Italic.woff') format('woff');
}

@font-face {
  font-weight: 600;
  font-style: italic;
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-SemiBoldItalic.woff2') format('woff2'), url('../fonts/OpenSans-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-weight: 300;
  font-style: italic;
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-LightItalic.woff2') format('woff2'), url('../fonts/OpenSans-LightItalic.woff') format('woff');
}

@font-face {
  font-weight: 600;
  font-style: normal;
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-SemiBold.woff2') format('woff2'), url('../fonts/OpenSans-SemiBold.woff') format('woff');
}

// Poppins font

// @font-face {
//   font-weight: 300;
//   font-style: normal;
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-Light.woff2') format('woff2'), url('../fonts/Poppins-Light.woff') format('woff');
// }

// @font-face {
//   font-weight: 200;
//   font-style: italic;
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-ExtraLightItalic.woff2') format('woff2'), url('../fonts/Poppins-ExtraLightItalic.woff') format('woff');
// }

// @font-face {
//   font-weight: 100;
//   font-style: italic;
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-ThinItalic.woff2') format('woff2'), url('../fonts/Poppins-ThinItalic.woff') format('woff');
// }

// @font-face {
//   font-weight: 200;
//   font-style: normal;
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-ExtraLight.woff2') format('woff2'), url('../fonts/Poppins-ExtraLight.woff') format('woff');
// }

// @font-face {
//   font-weight: 600;
//   font-style: italic;
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-SemiBoldItalic.woff2') format('woff2'), url('../fonts/Poppins-SemiBoldItalic.woff') format('woff');
// }

// @font-face {
//   font-weight: 800;
//   font-style: normal;
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-ExtraBold.woff2') format('woff2'), url('../fonts/Poppins-ExtraBold.woff') format('woff');
// }

// @font-face {
//   font-weight: 500;
//   font-style: normal;
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-Medium.woff2') format('woff2'), url('../fonts/Poppins-Medium.woff') format('woff');
// }

// @font-face {
//   font-weight: 300;
//   font-style: italic;
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-LightItalic.woff2') format('woff2'), url('../fonts/Poppins-LightItalic.woff') format('woff');
// }

// @font-face {
//   font-weight: 500;
//   font-style: italic;
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-MediumItalic.woff2') format('woff2'), url('../fonts/Poppins-MediumItalic.woff') format('woff');
// }

// @font-face {
//   font-weight: 600;
//   font-style: normal;
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-SemiBold.woff2') format('woff2'), url('../fonts/Poppins-SemiBold.woff') format('woff');
// }

// @font-face {
//   font-weight: normal;
//   font-style: italic;
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-Italic.woff2') format('woff2'), url('../fonts/Poppins-Italic.woff') format('woff');
// }

// @font-face {
//   font-weight: bold;
//   font-style: italic;
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-BoldItalic.woff2') format('woff2'), url('../fonts/Poppins-BoldItalic.woff') format('woff');
// }

// @font-face {
//   font-weight: bold;
//   font-style: normal;
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-Bold.woff2') format('woff2'), url('../fonts/Poppins-Bold.woff') format('woff');
// }

// @font-face {
//   font-weight: 100;
//   font-style: normal;
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-Thin.woff2') format('woff2'), url('../fonts/Poppins-Thin.woff') format('woff');
// }

// @font-face {
//   font-weight: 900;
//   font-style: italic;
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-BlackItalic.woff2') format('woff2'), url('../fonts/Poppins-BlackItalic.woff') format('woff');
// }

// @font-face {
//   font-weight: 800;
//   font-style: italic;
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-ExtraBoldItalic.woff2') format('woff2'), url('../fonts/Poppins-ExtraBoldItalic.woff') format('woff');
// }

// @font-face {
//   font-weight: normal;
//   font-style: normal;
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-Regular.woff2') format('woff2'), url('../fonts/Poppins-Regular.woff') format('woff');
// }

// @font-face {
//   font-weight: 900;
//   font-style: normal;
//   font-family: 'Poppins';
//   src: url('../fonts/Poppins-Black.woff2') format('woff2'), url('../fonts/Poppins-Black.woff') format('woff');
// }