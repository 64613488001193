$youngling: #8FBC5A;
$jeniusEmperor: #00ADEE;
$maxiMaster: #9552A0;
$xKnight: #F06823;
$flexiPadawan: #FBB315;
$blueJenius: #13a1d7;

#badge-sidebar {
    margin-top: 1rem;
    width: 100%;
    font-family: 'Jenius sans';
    font-weight: 700;

    h3 {
        font-size: 3rem;

        span {
            font-size: 1.5rem;
            color: $dark-gray;
            font-weight: 700;
            padding-left: .5rem;
        }
    }

    #badge-box {
        padding: .2rem;
        border-radius: 20px;

        p {
            margin-bottom: 0;
            font-size: .8rem;
            font-weight: 700;
        }

        img {
            height: 40px;
        }
    }
}

#badge-lists {
    width: 70%;

    .hide-badge {
        visibility: hidden;
        opacity: 0;
        height: 0;
        transition: visibility 0s, height 0.2s, opacity 0.5s linear;

        &.show {
            visibility: visible;
            opacity: 1;
            height: auto;
        }
    }

    .showmore-badge {
        display: block;
        text-align: center;
        font-size: .8rem;
        color: $dark-gray;
    }
}

#badge-page {
    #badge-box {
        padding: .5rem;
        margin-bottom: 2rem;
        font-family: 'Jenius sans';
        font-weight: 700;

        p {
            margin-bottom: 0;
            font-weight: 700;
            font-size: 12px;
        }

        h3 {
            margin-bottom: 0;
            font-size: 1.3rem;

            span {
                font-size: .8rem;
                font-weight: 700;
                padding-left: .5rem;
                color: #000;
            }
        }

        a {
            font-size: .6rem;
        }
    }
}

#badge-history-page {
    
    #badge-box {
        padding: .5rem;
        margin-bottom: 2rem;

        p {
            margin-bottom: 0;
            color: #B4B4B4;
        }
    }

    #badge-point small.date {
        display:block;
        padding-top: 5px;
        font-size: 70%;
        font-style: italic;
        color: #BDBDBE;
    }
}

.button.get-badges {
    background: rgba(112,195,246,1);
    background: -moz-linear-gradient(left, rgba(112,195,246,1) 0%, rgba(10,167,224,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(112,195,246,1)), color-stop(100%, rgba(10,167,224,1)));
    background: -webkit-linear-gradient(left, rgba(112,195,246,1) 0%, rgba(10,167,224,1) 100%);
    background: -o-linear-gradient(left, rgba(112,195,246,1) 0%, rgba(10,167,224,1) 100%);
    background: -ms-linear-gradient(left, rgba(112,195,246,1) 0%, rgba(10,167,224,1) 100%);
    background: linear-gradient(to right, rgba(112,195,246,1) 0%, rgba(10,167,224,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#70c3f6', endColorstr='#0aa7e0', GradientType=1 );
    text-transform: uppercase;
    border: 0;
    font-size: .7rem;

    &:hover {
        background: rgba(10,167,224,1);
        background: -moz-linear-gradient(left, rgba(10,167,224,1) 0%, rgba(112,195,246,1) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(10,167,224,1)), color-stop(100%, rgba(112,195,246,1)));
        background: -webkit-linear-gradient(left, rgba(10,167,224,1) 0%, rgba(112,195,246,1) 100%);
        background: -o-linear-gradient(left, rgba(10,167,224,1) 0%, rgba(112,195,246,1) 100%);
        background: -ms-linear-gradient(left, rgba(10,167,224,1) 0%, rgba(112,195,246,1) 100%);
        background: linear-gradient(to right, rgba(10,167,224,1) 0%, rgba(112,195,246,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0aa7e0', endColorstr='#70c3f6', GradientType=1 );
        color: #fff;
    }
}

.youngling {
    color: $youngling !important;
}
.jeniusEmperor {
    color: $jeniusEmperor !important;
}
.maxiMaster {
    color: $maxiMaster !important;
}
.xKnight {
    color: $xKnight !important;
}
.flexiPadawan {
    color: $flexiPadawan !important;
}

.youngling-bg {
    background: $youngling !important;
}
.jeniusEmperor-bg {
    background: $jeniusEmperor !important;
}
.maxiMaster-bg {
    background: $maxiMaster !important;
}
.xKnight-bg {
    background: $xKnight !important;
}
.flexiPadawan-bg {
    background: $flexiPadawan !important;
}

.dark-gray {
    color: $dark-gray !important;
}

.bg-grey {
    background: #E7E8E9;
}

table.dataTable tbody tr {
    background: transparent;
}

.dataTables_paginate.paging_simple_numbers {
    font-size: .7rem;
    margin-top: 1rem;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    width: 30px;
    height: 30px;

    &.next, &.previous {
        width: auto;
    }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:focus {
    background: #13a1d7;
    border: 1px solid transparent;
    border-radius: 2px;
    color: #fff !important;
}

table.dataTable td, table.dataTable th {
    border-top: none;
}

.blueJenius {
    color: $blueJenius;
}

.point-history {
    .item-list-tabs {
        display: none;
    }

    h3 {
        color: #aaa;
        font-family: 'Jenius sans';
        font-weight: 700;
    }
    .link-point-history {
        font-size: .8rem;
        font-weight: normal;
        font-style: italic;
    }
}