/*
 * jQuery FlexSlider v2.7.1
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 *
 */

/* ====================================================================================================================
 * FONT-FACE
 * ====================================================================================================================*/

@font-face {
  font-family: 'flexslider-icon';
  src: url('../fonts/flexslider-icon.eot');
  src: url('../fonts/flexslider-icon.eot?#iefix') format('embedded-opentype'), url('../fonts/flexslider-icon.woff') format('woff'), url('../fonts/flexslider-icon.ttf') format('truetype'), url('../fonts/flexslider-icon.svg#flexslider-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/

.flex-container a:hover,
.flex-slider a:hover {
  outline: none;
}

.slides,
.slides>li,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/

.flexslider {
  margin: 0;
  padding: 0;
}

.flexslider .slides>li {
  display: none;
  -webkit-backface-visibility: hidden;
}

.flexslider .slides img {
  // width: 100%;
  // display: block;
}

.flexslider .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .flexslider .slides {
  display: block;
}

* html .flexslider .slides {
  height: 1%;
}

.no-js .flexslider .slides>li:first-child {
  display: block;
}

/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/

.flexslider {
  margin: 0 0 60px;
  position: relative;
  zoom: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
  -o-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
}

.flexslider .slides {
  zoom: 1;
}

.flexslider .slides img {
  height: auto;
  -moz-user-select: none;
}

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.loading .flex-viewport {
  max-height: 300px;
}

@-moz-document url-prefix() {
  .loading .flex-viewport {
    max-height: none;
  }
}

.carousel li {
  margin-right: 5px;
}

.flex-direction-nav {
  *height: 0;
}

.flex-direction-nav a {
  text-align: center;
  text-decoration: none;
  display: block;
  width: 45px;
  height: 45px;
  border-radius: 5px;
  margin: -20px 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.flex-direction-nav a:before {
  font-family: "flexslider-icon";
  font-size: 35px;
  display: inline-block;
  vertical-align: middle;
  content: '\f001';
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
}

.flex-direction-nav a.flex-next:before {
  content: '\f002';
}

.flex-direction-nav .flex-prev {
  background-color: $white;
  left: -50px;
}

.flex-direction-nav .flex-next {
  background-color: $white;
  right: -50px;
}

.flexslider:hover .flex-direction-nav .flex-prev {
  
  opacity: 0.7;
}

.flexslider:hover .flex-direction-nav .flex-prev:hover {
  opacity: 1;
}

.flexslider:hover .flex-direction-nav .flex-next {
  opacity: 0.7;
}

.flexslider:hover .flex-direction-nav .flex-next:hover {
  opacity: 1;
}

.flex-direction-nav .flex-disabled {
  opacity: 0 !important;
  filter: alpha(opacity=0);
  cursor: default;
  z-index: -1;
}

.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000;
}

.flex-pauseplay a:before {
  font-family: "flexslider-icon";
  font-size: 20px;
  display: inline-block;
  content: '\f004';
}

.flex-pauseplay a:hover {
  opacity: 1;
}

.flex-pauseplay a.flex-play:before {
  content: '\f003';
}

.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;
}

.flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.flex-control-paging li a {
  width: 16px;
  height: 16px;
  display: block;
  cursor: pointer;
  text-indent: -9999px;
  border-radius: 20px;
  position: relative;
  border: 1px solid transparent;
  &:after {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    top: 50%;
    left: 50%;
    margin-left: -2px;
    margin-top: -2px;
    background: #666;
    border-radius: 2px;
  }
}

.flex-control-paging li a:hover {
  border: 1px solid #666;
}

.flex-control-paging li a.flex-active {
  border: 1px solid #666;
  cursor: default;
}

.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;
}

.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}

.flex-control-thumbs img {
  width: 100%;
  height: auto;
  display: block;
  opacity: .7;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.flex-control-thumbs img:hover {
  opacity: 1;
}

.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}

/* ====================================================================================================================
 * RESPONSIVE
 * ====================================================================================================================*/

@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 0;
  }
  .flex-direction-nav .flex-next {
    opacity: 1;
    right: 0;
  }
}

.other-posts-slider {
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  &.post {
    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-down(md) {
    margin-right: -30px;
    margin-left: -30px;
    line-height: 1.3;
    .flex-viewport {
      padding-right: 60px;
    }
  }
  h3 {
    margin-bottom: 30px;
    @include media-breakpoint-down(md) {
      padding-left: 30px;
      margin: 0 0 20px !important;
    }
  }
  .shadow {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    background: rgba(246, 246, 249, 0.5);
    z-index: 10;
    height: 100%;
    box-shadow: none !important;
  }
  .slides {
    margin-bottom: 15px;
    @include media-breakpoint-down(md) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  picture {
    display: block;
    // height: 240px;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 10px;
    // @include media-breakpoint-down(md) {
    //   height: 160px;
    //   width: 160px;
    // }
    img {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
  }
  .permalink {
    font-family: 'Jenius Sans';
    font-weight: 700;
    color: $black;
  }

  .date {
    color: #aaa;
    font-size: 12px;
    margin-top: 10px;
    @include media-breakpoint-down(md) {
      font-size: 10px;
    }
  }
}

.flex-control-paging {
  bottom: 0;
  margin-bottom: 1rem;
  position: relative;
}

.testimonials-slider {
  border-bottom: 1px solid $light-gray;
  margin-bottom: 5rem;
  .slides {
    margin-top: 30px;
  }
  @include media-breakpoint-down(md) {
    li {
      flex-direction: column;
      justify-content: center;
      align-items: center !important;
    }
  }
  picture {
    flex: 1 0 100px;
    width: 100px;
    // height: 100px;
    margin-right: 1rem;
    img {
      border-radius: 60px;
      background:$light-gray;
      width: 100px !important;
      height: 100px !important;
    }
    @include media-breakpoint-down(md) {
      margin-right: 0;
      position: relative;
      &:after {
        position: absolute;
        top: -20px;
        right: -20px;
        width: 50px;
        height: 50px;
        content: "";
        background: url(../images/bg-quote.png) no-repeat;
        background-size: contain;
      }
    }
  }
  .testi-content {
    font-family: 'Open Sans';
    font-size: 14px;
    color: #565656;
    @include media-breakpoint-down(md) {
      font-size: 12px;
      text-align: center;
      line-height: 1.8;
      margin-top: 30px;
    }
    article {
      margin-bottom: 20px;
    }
    strong {
      font-family: 'Jenius Sans';
    }
  }
}

.border-bottom-light-gray {
  border-bottom: 1px solid $light-gray;
}