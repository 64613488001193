.new-topic {
  margin-top: -20px;
  margin-bottom: 50px;
  padding-bottom: 20px;
  border-bottom: 1px solid $light-gray;
  @include media-breakpoint-down(md) {
    margin-top: 0;
    &.vhide {
      display: none;
    }
  }
  &.post-form-comment {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &.edit {
    margin-top: 0
  }
  &.no-border {
    border: 0;
  }
  &.bbp-reply-form {
    margin-top: 20px;
  }
  h4 {
    color: $black;
    font-weight: bold;
    font-size: 28px;
    font-family: 'Jenius Sans';
    strong {
      flex: 1 1 auto;
      margin-left: 20px;
    }
    .avatar,
    .def-avatar {
      flex: 0 0 60px;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      box-shadow: 0 3px 10px rgba(50, 50, 93, .1), 0 0px 5px rgba(0, 0, 0, .07);
      @include media-breakpoint-down(md) {
        width: 40px !important;
        height: 40px !important;
        flex: 0 0 40px;
      }
    }
    .def-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      text-transform: uppercase;
      font-size: 21px;
      font-family: 'Jenius Sans';
    }
  }
  .bbp-template-notice {
    margin: 30px 0;
    padding: 10px 18px;
    border-radius: 0;
    p {
      color: $black;
      font-size: 12px;
    }
  }

  #mceu_5,#mceu_6,#mceu_7,#mceu_8,#mceu_9,#mceu_10,#mceu_11,#mceu_12,#mceu_13, #add-typeform, #mceu_20, .mce-widget.mce-btn.mce-last, .mce-i-fullscreen{
    display: none !important;
  }

  .wp-editor-wrap {
    margin-top: 15px;
    width: 100% !important;
    iframe {
      height: 230px !important;
    }
  }

  #mceu_17 {
    position: relative;
    left: -5px;
  }

  .wp-media-buttons {
    position: absolute !important;
    right: 5px !important;
    top: 6px !important;
    button {
      width: 35px !important;
      overflow: hidden !important;
      border: 0 !important;
      background: none !important;
      box-shadow: none !important;
      padding-left: 10px !important;
    }
  }

  .wp-editor-container,
  .mce-toolbar-grp {
    border: 0 !important;
    background: none !important;
  }
}

#new-post {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  .page-heading {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  &.comment {
    margin-top: 0;
    .comment-form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 10px;
      .fields {
        flex-basis: 100%;
      }
      .note, .form-submit {
        display: flex;
        align-content: center;
        align-items: center;
        margin: 30px 0 0;
      }
      .form-submit {
        flex-basis: 40%;
        @include media-breakpoint-down(md) {
          flex-basis: 100%;
        }
      }
      .note {
        flex-basis: 60%;
        @include media-breakpoint-down(md) {
          flex-basis: 100%;
        }
        legend {
          margin: 0;
          padding-left: 20px;
          text-align: left;
          font-size: 12px;
          font-family: 'Open Sans';
        }
      }
    }
  }
  .fields {
    overflow: hidden;
    padding-top: 3px;
    border-radius: 20px;
    background: white;
    box-shadow: 0 5px 25px rgba(50, 50, 93, .1), 0 0px 10px rgba(0, 0, 0, .07);
    &.new {
      margin-top: 40px;
      label {
        em {
          color: $dark-gray;
          font-weight: normal;
          font-style: normal;
          b {
            font-weight: normal;
          }
        }
      }
    }
    .public {
      label {
        width: 100%;
        @include media-breakpoint-down(md) {
          font-size: 12px;
          line-height: normal;
          margin-top: 10px;
        }
        em {
          margin: 0;
          @include media-breakpoint-down(md) {
            font-size: 10px;
            display: block;
          }
        }
      }
    }
    &.plain {
      border: 0;
      background: none;
      box-shadow: none;
      margin-top: 20px;
    }
  }
  .jenius-forum-create-topic-title {
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    height: 52px;
    border-bottom: 1px solid $light-gray;

    &.dd {
      padding-right: 0;
    }

    &.t {
      min-height: 250px;
      height:auto !important;
      height: 250px;
      padding: 0 10px 15px;
      border: 0;
      align-content: flex-start;
      align-items: flex-start;
      textarea {
        width: 100%;
        height: 120px;
        line-height: 1.5;
      }
      &.s {
        min-height: 150px;
        height: 150px;
        padding: 0 20px;
        textarea {
          max-height: initial !important;
          min-height: initial !important;
          height: 120px;
        }
      }
    }
    &.submission {
      min-height: 300px;
      height:auto !important;
      height: 300px;
      padding: 0 10px 15px;
      textarea {
        padding-top: 10px;
      }
    }
    &.s {
      align-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      height: 90px;
      border: none;
      label {
        margin-bottom: -10px;
      }
    }
    &.r {
      border: 0;
      height: auto;
      label {
        display: flex;
        align-content: flex-start;
        align-items: flex-start;
        justify-content: flex-start;
        font-weight: normal;
        line-height: 1.5;
        margin-bottom: 10px;

        @include media-breakpoint-down(md) {
          height: inherit;
        }
        input {
          margin: -10px 10px 0 0;
        }
      }
    }
    input,
    textarea,
    select,
    label {
      margin: 0;
      padding: 0;
      height: 44px;
      border: 0;
      font-size: 14px;
      line-height: 44px;
      resize: none;
    }
    select {
      position: relative;
      width: 100%;
      color: $primary;
      font-weight: 600;
      z-index: 2;

      // -webkit-appearance: none;
      // -moz-appearance: none;
      // -o-appearance: none;
    }
    i.fa.fa-angle-down {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      margin-left: auto;
      font-size: 30px;
      z-index: 1;
    }
    label {
      margin-right: 10px;
      text-align: left;
      font-weight: 600;
      font-size: 14px;
    }
  }

  #mceu_5,#mceu_6,#mceu_7,#mceu_8,#mceu_9,#mceu_10,#mceu_11,#mceu_12,#mceu_13, #add-typeform, #mceu_20, .mce-widget.mce-btn.mce-last, .mce-i-fullscreen{
    display: none !important;
  }

  .wp-editor-wrap {
    margin-top: 15px;
    width: 100% !important;
    iframe {
      height: 230px !important;
    }
    iframe {
      height: 230px !important;
    }
  }

  #mceu_17 {
    position: relative;
    left: -5px;
  }

  #mceu_15 {
    &:before {
      box-shadow: none;
    }
  }

  .wp-media-buttons {
    position: absolute !important;
    right: 5px !important;
    top: 6px !important;
    button {
      width: 35px !important;
      overflow: hidden !important;
      border: 0 !important;
      background: none !important;
      box-shadow: none !important;
      padding-left: 10px !important;
    }
  }

  .wp-editor-container,
  .mce-toolbar-grp {
    border: 0 !important;
    background: none !important;
  }
}

.bbp-submit-wrapper,
.form-submit {
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
  @include media-breakpoint-down(md) {
    flex-direction: column;
    padding-bottom: 15px;
  }
  legend {
    padding-left: 20px;
    width: 60%;
    text-align: left;
    font-size: 12px;
    font-family: 'Open Sans';
    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
      width: 100%;
      font-size: 11px;
    }
  }
  a {
    font-weight: bold;
  }
  .co-button, .submit {
    margin-left: auto;
    width: 176px;
    @include media-breakpoint-down(md) {
      width: 100% !important;
      height: 48px;
      line-height: 48px;
      border-radius: 100px;
    }
  }
}

.form-submit .submit {
  @extend .co-button;

}

#new-post {
  textarea {
    padding: 15px 20px;
    width: 100%;
    height: 120px;
    border: 0;
    font-size: 14px;
    line-height: 1.5;
    resize: none;
  }
  .bbp-submit-wrapper {
    display: flex;
    align-content: center;
    align-items: center;
    margin-top: 30px;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
    legend {
      padding-left: 20px;
      width: 60%;
      text-align: left;
      font-size: 12px;
      font-family: 'Open Sans';
      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
        width: 100%;
        font-size: 11px;
      }
    }
    a {
      font-weight: bold;
    }
    .reply-buttons {
      margin-left: auto;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
    .co-button {
      width: 176px;
      @include media-breakpoint-down(md) {
        width: 100% !important;
      }
    }
  }
}

.reply-heading {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: start;
  padding: 20px 0;
  width: 100%;
  text-transform: uppercase;
  font-size: 18px;
  @include media-breakpoint-down(md) {
    font-size: 14px;
    padding-bottom: 0;
  }
  span {
    padding-left: 5px;
    color: $dark-gray;
    @include media-breakpoint-down(md) {
      font-weight: 600;
    }
  }
  .co-button {
    margin-left: auto;
    width: 176px;
    text-align: center;
  }
}

.topic-navlink {
  display: flex;
  clear: both;
  align-content: center;
  align-items: center;
  padding: 40px 0;
  border-bottom: 1px solid $light-gray;
  @include media-breakpoint-down(md) {
    padding: 20px 0;
  }
  span {
    display: flex;
    align-content: center;
    align-items: center;
    width: 50%;
    a {
      display: flex;
      flex: 0 0 80%;
      flex-direction: column;
      color: $black;
      font-weight: bold;
      font-size: 14px;
      font-family: 'Jenius Sans';

      em {
        display: inline-block;
        overflow: hidden !important;
        margin-right: auto;
        width: 180px;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-style: normal;
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
      b {
        margin-right: auto;
        width: 80%;
        color: $darker-gray;
        font-weight: 500;
        font-size: 12px;
        @include media-breakpoint-down(md) {
          font-size: 11px;
        }
      }
    }
    .fa {
      margin-top: -2px;
      margin-right: 30px;
      @include media-breakpoint-down(md) {
        margin-right: 15px;
      }
    }
    &:last-child {
      justify-content: flex-end;
      text-align: right;
      a {
        justify-content: flex-end;
        text-align: right;
        em, b {
          margin-right: 0;
          margin-left: auto;
          text-align: right;
        }
      }
      .fa {
        margin-right: 0;
        margin-left: 30px;
        @include media-breakpoint-down(md) {
          margin-left: 15px;
        }
      }
    }
  }
  .backto-parent {
    display: flex;
    align-content: center;
    align-items: center;
    flex: 0 0 40px;
    justify-content: center;
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: $light-gray;
    strong {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      width: 20px;
      height: 20px !important;
      em {
        position: relative;
        flex-grow: 1;
        margin: 0;
        width: 50%;
        height: 0;
        text-align: center;
        &:before {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -2px;
          margin-left: -2px;
          width: 4px;
          height: 4px;
          background: $darker-gray;
          content: ' ';
        }
      }
    }
  }
}

.bbpvotes-post-vote-link em {
  font-style: normal;
}

.comment-box {
  padding-left: 20px;
  padding-right: 20px;
}