.featured {
  margin: 0;
  padding: 0;
  list-style: none;
  >li {
    padding: 0;
    &:last-child .reply {
      border: 0;
      @include media-breakpoint-down(md) {
        border-bottom: 1px solid $light-gray;
      }
    }
  }
  &.replies li:first-child .topic {
    padding-bottom: 20px;
    border-bottom: 1px solid $light-gray;
  }
  &.forum {
    margin-top: -15px;
    .page-heading {
      padding-bottom: 20px;
    }
    @include media-breakpoint-down(md) {
      margin-top: 0;
      border-bottom: 0;
    }
  }
  &.past-events,
  &.event-detail {
    margin-top: 30px;
  }
  &.first-section {
    margin-top: 0;
  }
  &.closed {
    margin-top: 30px;
  }
  &.submit-ideas {
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid $light-gray;
    font-weight: bold;
    font-family: 'Jenius Sans';
    span {
      display: block;
    }
    .title {
      margin-bottom: 15px;
      font-weight: bold;
      font-size: 24px;
      font-family: 'Jenius Sans';
      @include media-breakpoint-down(md) {
        font-size: 18px !important;
      }
      &.ideas-header {
        strong {
          margin-left: 0;
        }
        margin-bottom: 10px;
      }
    }
  }
  &.submit-events {
    padding-top: 20px;;
    text-align: center;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    font-weight: bold;
    font-family: 'Jenius Sans';
    span {
      display: block;
    }
    .title {
      margin-bottom: 15px;
      font-weight: bold;
      font-size: 18px;
      font-family: 'Jenius Sans';
      @include media-breakpoint-down(md) {
        font-size: 18px !important;
      }
      &.events-header {
        strong {
          margin-left: 0;
        }
        margin-bottom: 10px;
      }
    }
    .submit-events-heading {
      padding: 10px;
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
    .btn-events {
      background: #13a1d7!important;
      text-align: center;
      width: 45%;
    }
  }
  .topic {
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    list-style: none;
    &.single-reply {
      padding-bottom: 10px !important;
    }
    &.ideas-topic {
      .bbpvotes-post-vote-link,
      .bbvoted {
        position: relative;
        color: $dark-gray;
        text-align: center;
        font-weight: bold;
        font-size: 24px;
        font-family: 'Jenius Sans';
        &:hover {
          color: $primary
        }
        &:before {
          display: block;
          font-size: 24px;
          @extend .fa;
          @extend .fa-angle-up;
        }
      }
      .bbvoted {
        color: $primary
      }
      .ideas-content {
        margin-left: 40px;
        .topic-meta {
          margin-top: 15px;
          margin-bottom: 15px;
        }
      }
    }
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: bold;
      font-family: 'Jenius Sans';
    }
    h3 {
      font-size: 20px;
    }
    h4 {
      font-size: 18px;
    }
    h5 {
      font-size: 16px;
    }
    h6 {
      font-size: 14px;
    }
    .featured-image img {
      margin-bottom: 15px;
      border-radius: 15px;
    }
    &.single .featured-image img {
      margin-bottom: 0;
    }
    article,
    p, li {
      &::first-letter {
        text-transform: uppercase;
      }
    }
    .fsticky {
      margin: 0 -20px -31px;
      padding: 20px;
      background: #F0F0F1;
      @include media-breakpoint-down(md) {
        margin : 0 -30px -31px;
        padding: 30px 30px 20px;
      }
    }
    .title {
      margin-bottom: 15px;
      font-weight: bold;
      font-size: 1.2rem;
      font-family: 'Jenius Sans';
      @include media-breakpoint-down(md) {
        font-size: 18px !important;
        margin-top: 20px;
      }
      &.ideas-header {
        strong {
          margin-left: 0;
        }
        margin-bottom: 10px;
      }
      strong {
        flex: 1 1 auto;
        margin-left: 20px;
        &::first-letter {
          text-transform: uppercase;
        }
      }
      &.no-avatar {
        strong {
          margin-left: 0;
        }
      }
      a {
        color: $black;
        font-weight: bold;
        font-family: 'Jenius Sans';
      }
      .avatar,
      .def-avatar {
        flex: 0 0 60px;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        box-shadow: 0 3px 10px rgba(50, 50, 93, .1), 0 0px 5px rgba(0, 0, 0, .07);
        @include media-breakpoint-down(md) {
          width: 40px !important;
          height: 40px !important;
          flex: 0 0 40px;
        }
      }
      .def-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        text-transform: uppercase;
        font-size: 21px;
        font-family: 'Jenius Sans';
      }
    }
  }
  .reply {
    margin: 0;
    padding: 30px 0;
    border-bottom: 1px solid $light-gray;
    .bbp-reply-form {
      margin: 0;
      padding: 0;
      border: 0;
      @include make-col-offset(2);
      h4 {
        margin-top: 20px;
        font-size: 20px;
      }
      form#new-post {
        margin-top: 15px;
        legend {
          display: none;
        }
        .reply-buttons {
          display: flex;
          align-content: center;
          align-items: center;
          width: 100%;
          @include media-breakpoint-down(md) {
            flex-direction: column-reverse;
            button {
              margin-bottom: 10px;
            }
            a {
              font-size: 14px;
            }
          }
        }
      }
    }
    .avatar,
    .def-avatar {
      width: 60px;
      height: 60px;
      border-radius: 30px;
      box-shadow: 0 3px 10px rgba(50, 50, 93, .1), 0 0px 5px rgba(0, 0, 0, .07);
      @include media-breakpoint-down(md) {
        width: 40px !important;
        height: 40px !important;
        flex: 0 0 40px;
      }
    }
    .def-avatar {
      display: flex;
      align-items: center;
      flex: 0 0 60px;
      justify-content: center;
      color: white;
      text-transform: uppercase;
      font-size: 21px;
      font-family: 'Jenius Sans';
    }
    .reply-avatar {
      padding: 0;
    }
    .reply-content {
      padding-left: 0;
      @include media-breakpoint-down(md) {
        padding-left: 5px;
      }
      h3 {
        font-weight: bold;
        font-size: 18px;
        font-family: 'Jenius Sans';
        &::first-letter {
          text-transform: uppercase;
        }
      }
      article,
      p {
        font-size: 14px;
        font-family: 'Open Sans';
        @include media-breakpoint-down(md) {
          font-size: 12px;
          line-height: 1.5;
        }
      }
      .date {
        color: $dark-gray;
        font-weight: 500;
        font-style: normal;
        font-size: 10px;
        span,
        a {
          margin-right: 10px;
        }
      }
    }
  }
  &.event-detail {
    padding-bottom: 20px;
    @include media-breakpoint-down(md) {
      border-top: 1px solid $light-gray;
      margin-top: -10px;
      padding-top: 30px;
    }
    +.topic-navlink {
      border-top: 1px solid $light-gray;
      margin-top: 20px;
    }
    .content-detail {
      padding-top: 20px;
      border-top: 1px solid $light-gray;
      @include media-breakpoint-down(md) {
        padding-top: 30px;
      }
    }
    .meta {
      padding-bottom: 20px;
      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 30px;

      }
      >div {
        width: 25%;
        @include media-breakpoint-down(md) {
          flex-basis: 30%;
          width: inherit;
          &:last-child {
            flex-basis: 100%;
            margin-top: 30px;
            text-align: center;
            .co-button {
              width: 70%;
              margin: 0 auto;
              float: none;
            }
          }
        }
        h4 {
          font-weight: 700;
          font-size: 14px;
          @include media-breakpoint-down(md) {
            font-size: 12px;
          }
        }
        article {
          font-weight: 400;
          font-size: 12px;
          @include media-breakpoint-down(md) {
            font-size: 10px;
          }
        }
      }
      .meta>div:nth-child(2) {
        width: 40%;
      }
    }
  }
  .bbp-threaded-replies {
    margin: -10px 0 0;
    padding: 0;
    background: #F6F6F9;
    list-style: none;
    @include media-breakpoint-down(md) {
      padding-left: 2rem;
      
    }
    >li {
      padding: 0;
      &:first-child {
        .reply {
          border-top: 1px solid $light-gray;
        }
      }
      .reply {
        padding: 20px 0;
        border-bottom: 1px solid $light-gray;
      }
      &:last-child {
        border-bottom: none;
        .reply {
          border-bottom: none;
          @include media-breakpoint-down(md) {
            border-bottom: 1px solid $light-gray;
          }
        }
      }
    }
    .reply {
      @include make-col-offset(1);
      @include media-breakpoint-down(md) {
        @include make-col-offset(0);
      }
    }
  }
}

.start-new-thread {
  margin-bottom: 40px;
  a {
    @extend .co-button;
    text-align: center;
    padding: 3px;
    height: 48px;
    line-height: 48px;
    width: 100%;
    font-size: 14px;
    border-radius: 30px;
  }
}

.bbp-reply-revision-log {
  display: none;
}