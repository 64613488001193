.site-header {
  flex-wrap: nowrap;
  box-shadow: 0px 4px 6px -1px rgba(15, 23, 42, 0.1);
  position: sticky;
  top:0;
  z-index: 500;
  background-color: $white;
  .brand {
    .logo {
      display: block;
      width: 120px;
      height: 55px;
      background: url(../images/logo-cocreate.png) center no-repeat;
      background-size: contain;
      font-size: 0;
      cursor: pointer;
      float: left;
    }
  }
  .menu {
    margin: 0;
    height: 50px;
    border-radius: 25px;
    background: $primary;
    color: white;
    list-style: none;
    line-height: 50px;

    &.logged-in {
      background: none;
      width: inherit !important;
      display: flex !important;
      justify-content: flex-end !important;
      li {
        padding: 0;
      }
      a {
        display: flex;
        align-items: center;
        flex-basis: 50px;
        justify-content: center;
        margin-left: 30px;
        width: 50px;
        height: 50px;
        color: $primary;
      }
      img, .def-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      .def-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: bold;
        font-size: 16px;
        font-family: 'Jenius sans';
      }
      .fa {
        font-size: 30px;
      }
      .notif {
        position: relative;
        background: url(../images/icon-bell.png) center no-repeat;
        em {
          position: absolute;
          top: 10px;
          right: 5px;
          display: inline-block;
          padding: 4px 6px;
          height: auto;
          border-radius: 10px;
          background: red;
          color: white;
          font-weight: normal;
          font-style: normal;
          font-size: 8px;
          font-family: 'Jenius Sans';
          line-height: normal;
        }
      }
    }

    &.off {
      width: 180px;
    }
    li {
      position: relative;
      padding: 0 15px;
      &:first-child:after {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -6px;
        width: 1px;
        height: 12px;
        background: white;
        content: "";
      }
      &.single:after {
        display: none;
      }
    }
    a {
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
      font-family: "Open Sans";
    }
  }
}
.site-header-m {
  display: none;

  .burger {
    width: 50%;
    position: relative;
    overflow: hidden;
    span {
      font-size: 11px;
      color: $dark-gray;
      overflow:hidden;
      white-space:nowrap;
      text-overflow: ellipsis;
      text-align: right;
      float: right;
      margin-right: 34px;
      width: calc(100% - 34px);
      display: inline-block;
    }
  }

  @include media-breakpoint-down(md) {
    display: block;
    padding: 30px 15px;
    .brand {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 0;
      margin-right: 0;
      padding-right: 0;
      flex-basis: 50%;
      a {
        display: flex;
        align-items: center;
        flex-basis: 40px;
        justify-content: center;
        margin-right: 15px;
        width: 40px;
        height: 40px;
      }
      .logo {
        margin-left: -15px;
        background: url(../images/logo-cocreate.png) center no-repeat;
        background-size: contain;
        font-size: 0;
        flex-basis: 100px;
        width: 100px;
        cursor: pointer;
      }
      img, .def-avatar {
        width: 34px;
        height: 34px;
        border-radius: 50%;
      }
      .def-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: bold;
        font-size: 16px;
        font-family: 'Jenius sans';
      }
      .fa {
        font-size: 30px;
      }
      .notif {
        position: relative;
        background: url(../images/icon-bell.png) center no-repeat;
        em {
          position: absolute;
          top: 6px;
          right: 3px;
          display: inline-block;
          padding: 4px 6px;
          height: auto;
          border-radius: 10px;
          background: red;
          color: white;
          font-weight: normal;
          font-style: normal;
          font-size: 8px;
          font-family: 'Jenius Sans';
        }
      }
    }
    .top-menu {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background: url(../images/icon-menu.png) center no-repeat;
      background-size: contain;
      text-indent: -99999em;
      &.active {
        background-image: url(../images/icon-menu-active.png);
      }
    }
  }
}

#menu-main {
  .nav-item {
    padding: 10px 20px;
    a {
      color: $darker-gray !important;
    }
    &:hover {
      a {
        color: $primary !important;
        font-weight: 600;
      }
    }
  }
  .current-menu-item {
    a {
      color: $black !important;
      font-weight: 600;
    }
  }
}


.coclub {
  display: flex;
  align-content: center;
  align-items: center;
  margin: -20px 0 30px;
  overflow: hidden;
  @include media-breakpoint-down(md) {
    flex-direction: column;
    padding: 0 15px;
  }
  header {
    flex-basis: 330px;
    @include media-breakpoint-down(md) {
      flex-basis: 100%;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 15px;
    }
    h5 {
      font-family: 'Jenius sans';
      font-size: 20px;
      text-transform: inherit;
      font-weight: bold;
      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
    }
    a {
      color: $primary;
      font-weight: 500;
      font-size: 12px;
      .fa {
        margin-left: 5px;
      }
      @include media-breakpoint-down(md) {
        margin-left: auto;
        font-size: 10px;
      }
    }
  }
  img, .def-avatar {
    width: 70px !important;
    height: 70px !important;
    border-radius: 50%;
    border: 1px solid $light-gray;
    @include media-breakpoint-down(md) {
      width: 40px !important;
      height: 40px !important;
    }
  }
  .def-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 30px;
    font-family: 'Jenius sans';
    text-transform: uppercase;
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    li {
      margin: 0 10px;
      min-width: 70px;
      @include media-breakpoint-down(md) {
        min-width: 40px;
        margin: 0 5px;
      }
    }
  }
}