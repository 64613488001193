#jen-reg {
  height: 100%;
  @include media-breakpoint-down(md) {
    height: inherit;
  }
  body {
    height: 100%;
    background-size:contain;
    @include media-breakpoint-down(md) {
      height: inherit;
      background: #ffffff;
    }
  }
}

#reg-log {
  // height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  margin: 0;
  padding: 0 0;
  @include media-breakpoint-down(md) {
    overflow: auto;
  }
  .login-with-sosmed-mobile {
    @include media-breakpoint-down(md) {
      background: url(../images/bg-login-mobile.png) center no-repeat;
      background-size: cover;
      padding-top: 70px;
      padding-bottom: 30px;
    }
    .login-socmed-title {
      text-align: center;
      padding: 10px;
      font-weight: bold;
      color: #fff;
      font-size: 28px;
      font-family: Jenius Sans;
    }
    .detail {
      display: flex;
      justify-content: space-between;
      padding-left: 15%;
      padding-right: 15%;
      font-size: 25px;
      .wraper-sosmed {
        margin: 5px 0 5px 0;
        width: 70px;
        border: 1px solid #e2e2e2;
        border-radius: 5px;
        background: #fff;
        text-align: center;
        height: 70px;
        padding: 15px;

        &.facebook {
          color: #43598f
        }
        &.twitter {
          color: #5ea9e2
        }
        &.gplus {
          color: #cc5a48;
        }
        span {
          text-align: center;
          margin-left: 30%;
          margin-right: 30%;
          color: #212529;
          font-weight: 600;
          font-family: "Open Sans";
          font-size: 12px;
        }
      }
    }   
  }
  .banner {
    @include media-breakpoint-down(md) {
      display: none !important;
    }
  }
  .back {
    position: relative;
    z-index: 1;
    display: inline-block;
    margin: 80px 0 40px;
    @include media-breakpoint-down(md) {
      margin: 40px 0 20px;
    }
  }
  .cocr-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 130px;
    &.re {
      margin-top: 80px;
      @include media-breakpoint-down(md) {
        margin: 40px 10px;
      }
    }
  }
  .reg-row {
    // height: 100%;
    @include media-breakpoint-down(md) {
      padding-top: 20px;
    }
  }
  .reg-form {
    position: relative;
    flex-direction: column;
    min-height: 610px;
    margin:auto;
    text-align: center;
    @include media-breakpoint-down(md) {
      max-width: 100% !important;
      flex: 0 0 100% !important;
      min-height: auto;

      .login-with {
        position: relative;
        color: #b6b6b6;
        text-align: center;
        background-color: #fff;
        span {
          position: relative;
          z-index: 1;
          padding: 0 10px;
          background: white;
          font-family: "Open Sans";
          font-size: 12px;
        }
        &:before {
          position: absolute;
          top: 50%;
          left: 20px;
          z-index: 0;
          width: 90%;
          height: 1px;
          background: $light-gray;
          content: '';
          transform: translateY(-50%);
        }
    }
    }
    h3 {
      margin-bottom: 30px;
      color: #333;
      text-align: left;
      font-weight: bold;
      font-size: 20px;
      font-family: 'Jenius Sans';
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }
    p {
      text-align: left;
      font-size: 14px;
    }
  }
  .captcha {
    margin-bottom: 30px;
  }
  .form-wrapper {
    display: flex;
    overflow: hidden;
    align-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 50px;
    background-color: white;
    padding: 0px 20px 10px 20px;
    border-radius: 5px;
    margin-bottom: 50px;
    @include media-breakpoint-down(md) {
      margin-top: 0px;
      padding: 0 20px;
      position: inherit;
      overflow: none;
    }
    .desc {
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }
    .regist {
      margin-top: 20px;
      text-align: center;
      font-size: 12px;
    }
    .form-title {
      display: block;
      margin-bottom: 10px;
      height: 30px;
      margin-top: 30px;
      span {
        float: right;
        font-size: 12px;
        color: #33a9fb;
      }
    }
    label {
      font-family: "Open Sans";
      font-size: 12px;
    }
    form {
      width: 100%;
      text-align: left;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      .jenius-registration-submit-button {
        height: 44px;
        border-radius: 5px;
        text-transform: uppercase;
        font-weight: 500;
        width: 100%;
        margin-top: 0px;
      }
    }
    .input-group {
      margin: 0 0 5px !important;
      //height: 44px;
      border: 1px solid $light-gray;
      border-radius: 5px;
      @include media-breakpoint-down(md) {
        height: auto;
        font-size: 12px;
      }
      &.container-jenius-registration-palceholder-error {
        border-color: #d00808;
        .input-group-prepend {
          .icon-register-email {
            border-color: #d00808;
            color: #d00808;
          }
        }
      }
      * {
        // border: 0;
        // background: none;
      }
      .input-group-prepend {
        text-align: center;
        .icon-register-email {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 30px;
          border-right: 1px solid $light-gray;
          color: darken($light-gray, 20%);
          font-size: 20px;
          &.fa {
            font-size: 26px;
            &.fa-calendar {
              font-size: 18px;
            }
            &.fa-map-marker {
              font-size: 24px;
            }
          }
          &:before {
            margin: 0 15px 0 12px;
          }
        }
      }
      input:focus { 
        border: 2px solid $primary;
      }

    }
    .input-wrapper {
      &.is-jenius {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        position: relative;
        .label {
          width: 47%;
          height: auto;
          span {
            position: absolute;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48%;
            height: 54px;
            border: 1px solid $light-gray;
            border-radius: 5px;
            color: #666;
            font-weight: normal;
            font-size: 13px;
            font-family: 'Jenius Sans';
            padding: 10px;
            @include media-breakpoint-down(md) {
              font-size: 12px;
            }
          }
          .emo {
            position: absolute;
            width: 100%;
            display: none;
            top: 90px;
          }
          input[type="radio"] {
            visibility: hidden;
            height: 54px;
            &.checked,
            &[checked],
            &:checked {
              height: 170px;
              + span {
                border-color: $primary;
                background: $primary;
                color: white;
                + .emo {
                  display: flex;
                  left: 0;
                  justify-content: space-between;
                  &.sad {
                    i {
                      background-position: right center;
                    }
                  }
                  i {
                    width: 50px;
                    height: 50px;
                    margin-right: 20px;
                    background: url(../images/emo.png) left center no-repeat;
                  }
                  label {
                    width: calc(100% - 70px);
                    text-align: left;
                    font-size: 14px;
                    font-family: 'Jenius Sans';
                    input {
                      border-bottom: 1px solid $primary;
                      color: $primary;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .titles {
        display: flex;
        //float: right;
        @include media-breakpoint-down(md) {
        //  float: left;
        }
        label {
          position: relative;
          margin-right: 5px;
          width: 42px;
          height: 42px;
        }
        span {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 42px;
          height: 42px;
          border: 1px solid $light-gray;
          border-radius: 5px;
          i {
            width: 26px;
            height: 32px;
            background: url(../images/mr.png) left top no-repeat;
          }
          &.mrs i {
            width: 25px;
            background: url(../images/mrs.png) left top no-repeat;
          }
        }
        input[type="radio"] {
          position: relative;
          visibility: hidden;
          margin: 0;
          padding: 0;
          &.checked+span,
          &[checked]+span,
          &:checked+span {
           // border-color: $primary;
           // background: $primary;
            i {
              background-position: right top;
            }
          }
        }
      }
      .agree {
        display: flex;
        width: 100%;
        text-align: left;
        font-size: 14px;
      }
      .cb {
        position: relative;
        visibility: hidden;
        margin: 0;
        margin-right: 15px;
        padding: 0;
        width: 25px;
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          visibility: visible;
          width: 25px;
          height: 25px;
          border: 1px solid $darker-gray;
          border-radius: 13px;
          background: #f6f6f9;
          content: '';
        }
        &.checked,
        &[checked],
        &:checked {
          &:before {
            border-color: #8FCD4D;
            background: #8FCD4D;
            color: white;
            text-align: center;
            font-size: 13px !important;
            line-height: 23px !important;
            @include fa-icon;
            @extend .fa;
            @extend .fa-#{check}:before;
          }
        }
      }
    }
    .forgot-submit {
      align-items: center;
      justify-content: center;
      height: 54px;
      margin-bottom: 50px;
      @include media-breakpoint-down(md) {
        flex-direction: column;
        justify-content: start;
        height: auto;
      }
      .forgot-password,
      .submit {
        margin: 0 !important;
        padding: 0;
        width: 60%;
        text-align: left !important;
        font-weight: 500;
        font-size: 12px;
        a {
          color: $dark-gray;
        }
        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
      .submit {
        width: 100%;
        @include media-breakpoint-down(md) {
          margin-top: 20px !important;
          width: 100%;
        }
        .jenius-registration-submit-button {
          float: right;
          margin: 0 auto;
          width: 100%;
          height: 54px;
          border-radius: 5px;
          text-transform: uppercase;
          font-weight: 500;
        }
      }
    }
    .error-line {
      margin: 0;
      padding: 0;
      padding-left: 10px;
      height: 20px;
      color: #d00808;
      text-align: left;
      font-size: 11px;
    }
    .login-with-sosmed {
      margin-top: 10px;
      &.top {
        @include media-breakpoint-down(md) {
          margin-top: 0;
        }
      }
      h6 {
        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;
        color: $dark-gray;
        text-align: center;
        span {
          position: relative;
          z-index: 1;
          padding: 0 10px;
          background: white;
          font-family: "Open Sans";
          font-size: 12px;
        }
        &:before {
          position: absolute;
          top: 50%;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 1px;
          background: $light-gray;
          content: '';
          transform: translateY(-50%);
        }
      }
      .detail {
        display: flex;
        justify-content: space-between;
        margin: 0;
        .wraper-sosmed {
          margin: 5px 0 5px 0;
          width: 100%;
          border: 1px solid $light-gray;
          border-radius: 5px;
          background: none;
          text-align: center;
          &.facebook {
            color: #43598f
          }
          &.twitter {
            color: #5ea9e2
          }
          &.gplus {
            color: #cc5a48;
          }
          span {
            text-align: center;
            margin-left: 30%;
            margin-right: 30%;
            color: #212529;
            font-weight: 600;
            font-family: "Open Sans";
            font-size: 12px;
          }
        }
      }
    }
    .regist-email {
      margin-top: 60px;
      color: #666;
      text-align: left;
      font-weight: 500;
      font-size: 14px;
    }
  }
}

#jen-modal-reg {
  overflow: hidden;
  padding: 10px;
  @include media-breakpoint-down(md) {
    padding: 50px !important;
  }
  .modal-jen-reg-content {
    font-family: 'Jenius Sans';
    padding: 20px;
    text-align: center;
    border-radius: 15px;
    @include media-breakpoint-down(md) {
      padding: 30px;
    }
    .modal-jen-reg-title {
      font-weight: 600;
      margin: 5px;
    }

    .modal-jen-reg-desc {
      font-size: 12px;
    }

    .modal-jen-reg-btn {
      word-spacing:10px;
      font-size: 12px;
      .btn {
        font-size: 12px;
        width: 100px;
      }
    }
  }
}

.footer-reglog {
  padding: 40px 0;
  font-family: 'Jenius Sans';
  @include media-breakpoint-down (md) {
    padding: 40px 15px;
    margin-top: 10px;
    color: $light-gray !important;
  }
  &.secondary {
    background: transparent;
    @include media-breakpoint-down(md) {
      background: white;
      margin-top: 0;
      padding-bottom: 64px;
    }
  }
    article,
    p {
      color: $dark-gray;
      font-size: 12px;
      line-height: 1.5;
      text-align: center;
      font-weight: bold;
      color: #fff !important;
    }
  a {
    color: #fff;
    @include media-breakpoint-down (md) {
      color: $light-gray;
    }
  }
}